import React, {useCallback, useState} from 'react';
import { editBed } from '../../actions/beds';

const EditBedForm = (props) => {

    const validateForm = useCallback((event) => {
        event.preventDefault();
        const form = event.target;
        editBed(form).then((result) => {
            if (result) {
                setTimeout(function() {
                    window.location = "/dashboard";
                }.bind(this), 1000)
            }
        });
    },[]);
    return (
        <form onSubmit={validateForm} className="formRequest">
            <input id="number" type="hidden" defaultValue={props.bed.number} className="form-control" />
            <div className="form-group">
                <label htmlFor="client"> Client </label>
                <input id="client" type="text" defaultValue={props.bed.client} className="form-control"/>
            </div>
            <div className="form-group">
                <label htmlFor="pharmacy">Pharmacie</label>
                <select name="pharmacy" id="pharmacy" className="form-control">
                    <option value="Cremers" selected={"Cremers" == props.bed.pharmacy}>Cremers</option>
                    <option value="Violaine" selected={"Violaine" == props.bed.pharmacy}>Violaine</option>
                    <option value="Mont à Camp" selected={"Mont à Camp" == props.bed.pharmacy}>Mont à Camp</option>
                    <option value="Grand Stade" selected={"Grand Stade" == props.bed.pharmacy}>Grand Stade</option>
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="model"> Modèle: </label>
                <select name="model" id="model" className="form-control">
                    <option value="Euro 3000" selected={"Euro 3000" == props.bed.model}>Euro 3000</option>
                    <option value="Euro 1600" selected={"Euro 1600" == props.bed.model}>Euro 1600</option>
                    <option value="Euro 1000" selected={"Euro 1000" == props.bed.model}>Euro 1000</option>
                    <option value="Euro 1002" selected={"Euro 1002" == props.bed.model}>Euro 1002</option>
                    <option value="Euro 1000 Boiserie" selected={"Euro 1000 Boiserie" == props.bed.model}>Euro 1000 Boiserie</option>
                    <option value="Sotec" selected={"Sotec" == props.bed.model}>Sotec</option>
                    <option value="Autre" selected={"Autre" == props.bed.model}>Autre</option>
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="motors"> Nombre de moteurs: </label>
                <input id="motors" type="number" defaultValue={props.bed.motors} className="form-control" pattern="[0-9]*" inputmode="numeric"/>
            </div>
            <div className="form-group">
                <label htmlFor="motors"> Année: </label>
                <input id="year" type="number" defaultValue={props.bed.year} className="form-control" pattern="[0-9]*" inputmode="numeric"/>
            </div>
            <div className="form-group">
                <label htmlFor="serialNumber"> Numéro de série: </label>
                <input id="serialNumber" type="text" defaultValue={props.bed.serialNumber} className="form-control"/>
            </div>
            <div className="form-group form-check form-switch">
                <label htmlFor="isRent"> En Location ? </label>
                <input id="isRent" type="checkbox" defaultChecked={props.bed.isRent} className="form-check-input"/>
            </div>
            <div className="form-group">
                <input type="submit" value="Envoyer" className="btn btn-primary"/>
            </div>
        </form>
    );
};

export {
    EditBedForm
};