'use strict';

import {
    openNotification
} from "./notifications";


export const requestBed = (form, user) => {
    return fetch(`${process.env.REACT_APP_API_URL}/request/add`, {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
            client: form.client.value,
            pharmacy: form.pharmacy.value,
            date: form.date.value,
            address: form.address.value + ' ' + form.postcode.value + ' ' + form.city.value,
            comment: form.comment.value,
            user: user.name
        })
    })
    .then(async res => {
        if (res.status === 201) return res.json();
        throw await res.json();
    })
    .then(res => {
        openNotification({
            type: 'success',
            message: res.message
        })
        return true;
    })
    .catch(err => {
        openNotification({
            type: 'error',
            message: err.message
        })
        return false;
    });
}

export const endRent = (id) => {
    return fetch(`${process.env.REACT_APP_API_URL}/beds/end/${id}`, {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
        })
    })
    .then(async res => {
        console.log(res)
        if (res.status === 200) return res.json();
        throw await res.json();
    })
    .then(res => {
        openNotification({
            type: 'success',
            message: res.message
        })
        return true;
    })
    .catch(err => {
        openNotification({
            type: 'error',
            message: err.message
        })
        return false;
    });
}

export const startRent = (bedNumber, elementRequest) => {
    return fetch(`${process.env.REACT_APP_API_URL}/beds/start/${bedNumber}`, {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
            client: elementRequest.client,
            pharmacy: elementRequest.pharmacy,
        })
    })
    .then(async res => {
        console.log(res)
        if (res.status === 200) return res.json();
        throw await res.json();
    })
    .then(res => {
        openNotification({
            type: 'success',
            message: res.message
        })
        return true;
    })
    .catch(err => {
        openNotification({
            type: 'error',
            message: err.message
        })
        return false;
    });
}

export const deleteRequest = (id) => {
    return fetch(`${process.env.REACT_APP_API_URL}/request/delete/${id}`, {
        method: 'DELETE',
        headers: new Headers({
            'Content-Type': 'application/json',
        })
    })
    .then(async res => {
        console.log(res)
        if (res.status === 200) return res.json();
        throw await res.json();
    })
    .then(res => {
        openNotification({
            type: 'success',
            message: res.message
        })
        return true;
    })
    .catch(err => {
        openNotification({
            type: 'error',
            message: err.message
        })
        return false;
    });
}

export const createBed = (form) => {
    return fetch(`${process.env.REACT_APP_API_URL}/beds/add`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
                number: form.number.value,
                pharmacy: form.pharmacy.value,
                model: form.model.value,
                isRent: form.isRent.checked,
                client: form.client.value,
                motors: form.motors.value,
                year: form.year.value,
                serialNumber: form.serialNumber.value
            })
        })
        .then(async res => {
            if (res.status === 201) return res.json();
            throw await res.json();
        })
        .then(res => {
            openNotification({
                type: 'success',
                message: res.message
            })
            return true;
        })
        .catch(err => {
            openNotification({
                type: 'error',
                message: err.message
            })
            return false;
        });
};

export const editBed = (form) => {
    return fetch(`${process.env.REACT_APP_API_URL}/beds/edit/${form.number.value}`, {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
            pharmacy: form.pharmacy.value,
            model: form.model.value,
            isRent: form.isRent.checked,
            client: form.client.value,
            motors: form.motors.value,
            year: form.year.value,
            serialNumber: form.serialNumber.value
        })
    })
    .then(async res => {
        console.log(res)
        if (res.status === 200) return res.json();
        throw await res.json();
    })
    .then(res => {
        openNotification({
            type: 'success',
            message: res.message
        })
        return true;
    })
    .catch(err => {
        openNotification({
            type: 'error',
            message: err.message
        })
        return false;
    });
}

export const deleteBed = (number) => {
    return fetch(`${process.env.REACT_APP_API_URL}/beds/delete/${number}`, {
        method: 'DELETE',
        headers: new Headers({
            'Content-Type': 'application/json',
        })
    })
    .then(async res => {
        console.log(res)
        if (res.status === 200) return res.json();
        throw await res.json();
    })
    .then(res => {
        openNotification({
            type: 'success',
            message: res.message
        })
        return true;
    })
    .catch(err => {
        openNotification({
            type: 'error',
            message: err.message
        })
        return false;
    });
}